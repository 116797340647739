import { InjectionToken, Provider } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { isArray } from 'lodash-es';

import { CourseItemInterface } from '@app/state/courses/interfaces/course-item.interface';
import { AllCoursesCount, CoursesState } from '@app/state/courses/courses.state';
import { CategoryInterface } from '@app/interfaces/category.interface';
import { PaginationWithData } from 'libraries/college-settings';
import { LoadCoursesOptionsInterface } from '@state/courses/interfaces/load-courses.options.interface';
import { tapLoad } from '@shared/modules/air/air-modules/loaders';

export const COURSES_LIST = new InjectionToken<Observable<CourseItemInterface[]>>('Courses list stream');
export const COURSES_CATEGORIES_LIST = new InjectionToken<Observable<CategoryInterface[]>>(
  'Courses categories list stream',
);

export const COURSES_PROVIDERS: Provider[] = [
  {
    provide: COURSES_LIST,
    useFactory: coursesFactory,
    deps: [ActivatedRoute, CoursesState],
  },
  {
    provide: COURSES_CATEGORIES_LIST,
    useFactory: categoriesFactory,
    deps: [CoursesState],
  },
];

function categoriesFactory(store: CoursesState): Observable<CategoryInterface[]> {
  if (!store.snapshot.categories.length) {
    store.loadCategories().pipe(tapLoad('all_students.load_all'), take(1)).subscribe();
  }

  return store.categories$.pipe(
    tapLoad('all_students.load_all'),
    filter((categories) => isArray(categories)),
  );
}

function coursesFactory(
  { queryParams }: ActivatedRoute,
  store: CoursesState,
): Observable<PaginationWithData<CourseItemInterface[]>> {
  return queryParams
    .pipe(
      switchMap((params) => {
        const options: LoadCoursesOptionsInterface = {};
        const categoryId = params.category_id;
        options.count = params.count || AllCoursesCount;
        if (categoryId) {
          options.category_id = categoryId;
        }
        store.loadCourses({ options, isHomePage: false });
        return store.courses$;
      }),
    )
    .pipe(filter((courses) => !!courses.data.length));
}
